<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="payaccounts" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['fullname','accountname']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:30%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入名称模糊查询" style="width:100%"
                                    @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.fullname"
                                @click="openfinancialpayaccount(slotProps.data,false)" class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading"
                :saveBeforeMethod="saveBefore" :currentRow="currentRow" :childNewDataConfig="childNewDataConfig"
                :closeParentMehod="closeMaximizable" :freshListMethod="freshList" :childTableFooter="childTableFooter"
                :childDelDataConfig="childDelDataConfig" :dropOtherConfig="dropOtherConfig"
                :childExpandePageConfig="childExpandeConfig" :pageJsonConfig="pageJsonConfig" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="数据导出" v-model:visible="importExcelDisplay" :style="{width: '30vw'}" :maximizable="false"
        :modal="true">
        <div v-if="!startimport" class="grid">
            <div class="col-8">
                <div class="text-left">
                    <Calendar placeholder="开始时间(收货日期)" v-model="startReceiptDate" dateFormat="yy-mm-dd" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Calendar placeholder="截至时间(收货日期)" v-model="endReceiptDate" dateFormat="yy-mm-dd" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="col-4">
                <div class="text-right">
                    <Button label="导出" icon="pi pi-external-link" @click="costnoverificationexportStart" />
                </div>
            </div>
        </div>
        <div v-if="startimport" class="grid p-fluid mt-3">
            <div v-if="importFinish" class="field col-12 md:col-12">
                <Button label="数据处理完成,请点击下载" @click="getExcleFilePatch" class="p-button-link" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <ProgressBar mode="indeterminate" style="height: .5em" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <label>数据处理中....</label>
            </div>
        </div>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '776';
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                startReceiptDate: '',
                endReceiptDate: '',
                startimport: false,
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                importExcelDisplay: false,
                importFinish: false,
                excelFilePatch: '',
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                flowId: '',
                startStateId: '',
                currentState: '',
                currentRow: {
                    bindpage: 'financialpayaccount',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '我方付款账户',
                selectedKey: {
                    776: true
                },
                childNewDataConfig: {
                    products: false,
                    files: true,
                    contractterms: true,
                },
                childDelDataConfig: {},
                childTableFooter: {},
                dropOtherConfig: {},
                pageJsonConfig: {},
                expandedKey: null,
                childExpandeConfig: {},
                payaccounts: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '财务操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '新建费用付款',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                window.location.href =
                                    '/#/crmfinancial/expensepay?newexpensepay=1';
                            }
                        }, {
                            separator: true
                        }, {
                            label: '新建费用核销',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                window.location.href =
                                    '/#/crmfinancial/costverification?newcostverification=1';
                            }
                        }, {
                            separator: true
                        }, {
                            label: '新建对方收款账户',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                window.location.href =
                                    '/#/crmfinancial/collectionaccount?newdata=1';
                            }
                        }, {
                            separator: true
                        }, {
                            label: '新建我方付款账户',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createfinancialpayaccount();
                            }
                        }, {
                            separator: true
                        }, {
                            label: '新建款项名称',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                window.location.href =
                                    '/#/crmfinancial/paymentitem?newdata=1';
                            }
                        }, {
                            separator: true
                        }, {
                            label: '未核销数据导出',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.costnoverificationexport();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delfinancialpayaccount();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getfinancialpayaccount(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.initFilters1();
                    this.loadMenuTree();
                    this.loadUserAuth();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('770', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '770',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;

                        var newdataflag = this.$route.query['newdata'];
                        if (newdataflag) {
                            this.createfinancialpayaccount();
                        }
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '我方付款账户', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: []
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'fullname,accountname',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{financialpayaccountlist(where:" + JSON.stringify(listwhere) +
                    "){id fullname address tel accountname bank bankaccountnum creater createtime}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.payaccounts = jsonData.data.financialpayaccountlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            createfinancialpayaccount() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreatePaymentAccount')) {
                    MessageTip.warnmsg('请联系管理员开通新建我方付款账户权限');
                    return;
                }
                this.recordsubject = '新建我方付款账户';
                this.currentRow = {
                    bindpage: 'financialpayaccount',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: '',
                };
                this.displayMaximizable = true;
            },
            openfinancialpayaccount(v_data, v_isCopy) {
                this.recordsubject = v_data.itemname;
                this.currentRow = {
                    isCopy: v_isCopy,
                    bindpage: 'financialpayaccount',
                    id: v_data.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: '',
                };
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            delfinancialpayaccount() {
                this.$confirm.require({
                    message: '确认要删除?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        if (!this.selectedRow || this.selectedRow.length == 0) {
                            MessageTip.warnmsg('请选择要删除的我方付款账户');
                            return;
                        }
                        var financialitemIds = [];
                        for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                            financialitemIds.push({
                                "id": this.selectedRow[k1]["id"]
                            });
                        }
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "delete{financialpayaccountlist(o:" + JSON.stringify(financialitemIds) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                this.selectedRow = null;
                                this.loadData(1);
                                MessageTip.successmsg('删除成功');
                            } else {
                                MessageTip.warnmsg('删除失败');
                            }
                        });
                    },
                    reject: () => {}
                });
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveBefore(v_data) {
                console.log('saveBefore:');
                console.log(v_data);
                v_data['approvestatus'] = 1;
                v_data['accounttype'] = 1;
                return v_data;
            },
            saveData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo(true);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            },
            costnoverificationexport() {
                this.excelFilePatch = '';
                this.startReceiptDate = '';
                this.endReceiptDate = '';
                this.importFinish = false;
                this.importExcelDisplay = true;
                this.startimport = false;
            },
            costnoverificationexportStart() {
                this.startimport = true;
                var startReceiptDateStr = '';
                var endReceiptDateStr = '';
                if (this.startReceiptDate != '') {
                    startReceiptDateStr = CommonJs.formatDate('DateString', this.startReceiptDate);
                }
                if (this.endReceiptDate != '') {
                    endReceiptDateStr = CommonJs.formatDate('DateString', this.endReceiptDate);
                }
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                        '/calf/plat/CostNoVerificationExport?appkey=' + sessionStorage.getItem("appkey"), {
                            StartTime: startReceiptDateStr,
                            EndTime: endReceiptDateStr
                        })
                    .then(
                        res => {
                            console.log(res);
                            if (res.data.errcode == "0") {
                                var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                console.log('costnoverificationexport:');
                                console.log(retObj);
                                this.excelFilePatch = retObj.filepatch;
                                this.importFinish = true;
                            } else {
                                this.importExcelDisplay = false;
                                MessageTip.warnmsg(res.data);
                            }
                        });
            },
            getExcleFilePatch() {
                location.href = '/calf/plat/file/' + this.excelFilePatch + '?appkey=' + sessionStorage.getItem(
                    "appkey");
                return;
            }
        },
        components: {
            NewUniversalPage,
            'AppFooter': AppFooter,
        }
    }
</script>